import $ from 'jquery'
import { parse } from 'query-string'

export function writeQueryString (payload) {
    return $.param(payload || [])
}

export function readQueryString (str) {
  return parse(str || document.location.search.slice(1))
}
