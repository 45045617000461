/* MANTL analytics */
/* 2017 */

import { readQueryString } from '../src/helpers/queryParams';
import Cookies from 'js-cookie';

function storeSessionData (sessionData) {
  let sStor = Cookies.getJSON('mantl_s');
  Cookies.set(
    'mantl_s',
    JSON.stringify({ ...sStor, sD: sessionData }),
    { ...(process.env.COOKIE_DOMAIN ? { domain: process.env.COOKIE_DOMAIN } : {}) }
  );
}


// first touch attribution
// progressive profiling

function findOrCreateSessionId () {
  let sStor = Cookies.getJSON('mantl_s');
  let queryParams = readQueryString();

  if (!sStor || !sStor.sD) {
    const sessionData = {
      rU: document.referrer, // referringUrl
      qP: queryParams, // queryParams
      eU: window.location.href, // entranceUrl
    };

    storeSessionData(sessionData);
  } else if (sStor.sD) {
    storeSessionData(Object.assign({}, sStor.sD, {
      qP: Object.assign({}, queryParams, sStor.sD.qP)
    }))
  }
}

findOrCreateSessionId();
